import React from "react";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import Resume from "../assets/data/Resume.pdf";
import * as Icon from "react-bootstrap-icons";
const Contact = () => {
  return (
    <>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
        <Box sx={{ mx: "auto", mt: "150px", mb: "150px" }}>
          <Typography variant="h4" sx={{ textAlign: "center", mt: 5 }}>
            Contact
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2, mt: 5 }}>
            <IconButton
              href="https://www.linkedin.com/in/ryan-malloy-/"
              target="_blank"
              rel="noreferrer"
              sx={{ backgroundColor: "white", height: "48px", width: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Icon.Linkedin style={{ color: "black" }} />
            </IconButton>
            <IconButton
              href="https://github.com/RyanMalloy"
              target="_blank"
              rel="noreferrer"
              sx={{ backgroundColor: "white", height: "48px", width: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Icon.Github style={{ color: "black" }} />
            </IconButton>
            <IconButton
              href="mailto:ryanpmalloy@icloud.com"
              target="_blank"
              rel="noreferrer"
              sx={{ backgroundColor: "white", height: "48px", width: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Icon.Mailbox style={{ color: "black" }} />
            </IconButton>

            <Button href={Resume} download endIcon={<Icon.Download style={{ color: "white" }} />} sx={{ backgroundColor: "#e05555", px: 2, borderRadius: "32px", height: "48px" }}>
              <Typography variant="body1">Resume</Typography>
            </Button>
          </Box>
        </Box>
      </motion.div>
    </>
  );
};

export default Contact;
