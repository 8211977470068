import React from "react";
import { Grid, Typography, Box, Chip } from "@mui/material";
import { motion } from "framer-motion";
import TechStack from "../assets/data/TechStack";
import Hero2 from "../assets/images/Hero2.svg";
const TechStackSection = () => {
  return (
    <Grid container sx={{ mt: "150px", px: 5, display: "flex", alignItems: "center" }}>
      <Grid item xs={12} md={4}>
        <motion.img src={Hero2} alt="Hero" style={{  width: "100%" }} />
      </Grid>
      <Grid id="tech-stack" item xs={12} md={8} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <motion.div initial={{ opacity: 0, x: 500 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 2 }}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Tech Stack
          </Typography>
        </motion.div>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Languages
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.languages.map((lang) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={lang.name} avatar={<img key={lang.name} src={lang.src} alt={lang.name} />} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Frameworks
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.frameworks.map((framework) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={framework.name} avatar={<img key={framework.name} src={framework.src} alt={framework.name} />} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          CSS Frameworks
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.cssFrameworks.map((cssFramework) => (
            <Chip
              className="tech-stack-chip"
              sx={{ px: 1, backgroundColor: "#EEEEEE" }}
              label={cssFramework.name}
              avatar={<img key={cssFramework.name} src={cssFramework.src} alt={cssFramework.name} />}
            />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Database
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.database.map((db) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={db.name} avatar={<img key={db.name} src={db.src} alt={db.name} />} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Backend
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.backend.map((backend) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={backend.name} avatar={<img key={backend.name} src={backend.src} alt={backend.name} />} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          CI / CD
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.cicd.map((cicd) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={cicd.name} avatar={<img key={cicd.name} src={cicd.src} alt={cicd.name} />} />
          ))}
        </Box>

        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          Version Control
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          {TechStack.versionControl.map((vc) => (
            <Chip className="tech-stack-chip" sx={{ px: 1, backgroundColor: "#EEEEEE" }} label={vc.name} avatar={<img key={vc.name} src={vc.src} alt={vc.name} />} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TechStackSection;
