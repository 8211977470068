import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import ProjectData from "../assets/data/ProjectData";
import ProjectCard from "../components/Cards/ProjectCard";

const Projects = () => {
  return (
    <>
      {/* Projects */}
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
        <Box sx={{ maxWidth: "600px", mx: "auto", mt: "150px" }}>
          <Typography variant="h4" sx={{ textAlign: "center", mt: 5 }}>
            Projects
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
            Discover some of my key projects that showcase my expertise in web development and innovative problem-solving.
          </Typography>
        </Box>
      </motion.div>

      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
        <Grid container sx={{ mt: 5 }}>
          {Object.values(ProjectData).map((project, index) => (
            <Grid key={index} item lg={4} md={6} xs={12} sx={{ p: "8px" }}>
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </>
  );
};

export default Projects;
