import visualLiteLogo from "../images/projectLogos/visualLite.png";
import cryptoLogo from "../images/projectLogos/cryptoBook.png";
import reachLogo from "../images/projectLogos/reach.png";
import ursackLogo from "../images/projectLogos/Ursack.svg";
import ApartmentScrape from "../images/projectLogos/apartmentScrape.png";

const ProjectData = {
  reach: {
    logo: reachLogo,
    title: "Reach",
    subTitle: "Social Media Web App",
    logos: [
      { name: "React", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg" },
      { name: "Node", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg" },
      { name: "Firebase", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg", },
      { name: "Azure", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/azure/azure-original.svg", },
    ],
    content: "Developed a social media web app using React for the frontend, Node.js for backend logic, and Firebase for real-time data storage and retrieval.",
    link: "https://www.reachsocial.app/",
  },
  crypto: {
    logo: cryptoLogo,
    title: "Crypto Order Book",
    subTitle: "Cryptocurrency Tracker",
    logos: [
      { name: "TypeScript", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg", },
      { name: "React", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg" },
      { name: "Node",  src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg", },
      { name: "Ant Design", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/antdesign/antdesign-original.svg", },
    ],
    content: "Built a cryptocurrency tracker using React for the frontend, Node.js for backend logic, and Ant Design for styling.",
    link: "https://github.com/RyanMalloy/crypto-order-book",
  },
  ursack: {
    logo: ursackLogo,
    title: "Ursack",
    subTitle: "E-commerce Web App",
    logos: [
      { name: "HTML", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg", },
      { name: "CSS", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg", },
      { name: "JavaScript", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg", },
    ],
    content: "Designed and developed an e-commerce website using HTML, CSS, and JavaScript. Integrated with Shopify for product management.",
    link: "https://ursack.com/",
  },
  visualLite: {
    logo: visualLiteLogo,
    title: "VisualLite",
    subTitle: "Database Visualization Web App",
    logos: [
      { name: "React", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg" },
      { name: "Node",  src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg", },
      { name: "Firebase", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg", },
      { name: "Neo4j", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/neo4j/neo4j-original.svg", },
      { name: "C#", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg", },
    ],
    content: "Led the development of a beer recommendation web app using React for the frontend, C# for backend logic, and Firebase for real-time data storage and retrieval.",
    link: "https://github.com/RyanMalloy/VisualLite",
  },

  apartmentScrape: {
    logo: ApartmentScrape,
    title: "Apartment Scraper",
    subTitle: "Web Scraping Tool",
    logos: [
      { name: "JavaScript", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg", },
      { name: "Node", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg", },
      { name: "Playwright", src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/playwright/playwright-original.svg", },
    ],
    content: "Built a web scraping tool using Javascript and Playwright to scrape apartment listings from apartments.com.",
    link: "https://github.com/RyanMalloy/apartment-scrape",
  },
};

export default ProjectData;
