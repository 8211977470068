import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import PixelHeadshot from "../assets/images/pixel-headshot.png";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Resume from "../assets/data/Resume.pdf";
const Navbar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}>
        {isMobile ? (
          <IconButton component={Link} to="/">
            <img src={PixelHeadshot} alt="Pixel Headshot" style={{ height: "48px", width: "48px", borderRadius: "50%" }} />
          </IconButton>
        ) : (
          <Typography variant="h5">Ryan Malloy</Typography>
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            href="https://github.com/RyanMalloy"
            target="_blank"
            rel="noreferrer"
            sx={{ backgroundColor: "white", height: "36px", width: "36px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Icon.Github style={{ color: "black", width: "24px", height: "24px" }} />
          </IconButton>
          <IconButton href={"https://www.linkedin.com/in/ryan-malloy-/"} target="_blank" rel="noreferrer" sx={{ backgroundColor: "#0077B5", width: "36px", height: "36px" }}>
            <Icon.Linkedin style={{ color: "white", width: "24px", height: "24px" }} />
          </IconButton>
          <a href={Resume} download style={{ textDecoration: "none" }}>
            <Button endIcon={<Icon.Download style={{ color: "white" }} />} sx={{ backgroundColor: "#e05555", px: 2, borderRadius: "32px" }}>
              <Typography variant="body1">Resume</Typography>
            </Button>
          </a>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
