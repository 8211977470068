const TechStack = {
  languages: [
    {
      name: "JavaScript",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    },
    {
      name: "TypeScript",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    },
    {
      name: "HTML",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    },
    {
      name: "CSS",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    },
    {
      name: "Python",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    },
    {
      name: "Java",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    },
    {
      name: "C#",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
    },
    {
      name: "SQL",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    },
  ],
  frameworks: [
    {
      name: "React",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    },
    {
      name: "Next.js",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original-wordmark.svg",
    },
    {
      name: "Angular",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/angularjs/angularjs-original.svg",
    },
    {
      name: "Spring Boot",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/spring/spring-original.svg",
    },
  ],
  cssFrameworks: [
    {
      name: "Material-UI",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    },
    {
      name: "Bootstrap",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-plain.svg",
    },
    {
      name: "SASS",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg",
    },
    {
      name: "Ant Design",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/antdesign/antdesign-original.svg",
    },
  ],
  database: [
    {
      name: "Firebase",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    },
    {
      name: "MongoDB",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    },
    {
      name: "Neo4j",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/neo4j/neo4j-original.svg",
    },
    {
      name: "MySQL",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    },
    {
      name: "SQLite",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sqlite/sqlite-original.svg",
    },
  ],
  backend: [
    {
      name: "Node.js",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    },
    {
      name: "Express",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
    },
    {
      name: "Flask",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    },
    {
      name: "Spring Boot",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/spring/spring-original.svg",
    },
  ],
  cicd: [
    {
      name: "Azure",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/azure/azure-original.svg",
    },
    {
      name: "GitHub Actions",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/githubactions/githubactions-original.svg",
    },

    {
      name: "Docker",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg",
    },
  ],
  versionControl: [
    {
      name: "Git",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    },
    {
      name: "GitHub",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg",
    },
    {
      name: "Bitbucket",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bitbucket/bitbucket-original.svg",
    },
    {
      name: "GitLab",
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/gitlab/gitlab-original.svg",
    },
  ],
};

export default TechStack;
