import React from "react";
import ReachLogo from "../assets/images/projectLogos/reach.png";
import SavageLogo from "../assets/images/projectLogos/savage.png";
import MMILogo from "../assets/images/projectLogos/mmi.png";
import LibertyLogo from "../assets/images/projectLogos/liberty.png";
import { Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
const Experience = () => {
  return (
    <>
      {/* Experience */}
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
        <Box sx={{ maxWidth: "600px", mx: "auto", mt: "150px", mb: 5 }}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Experience
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
            Explore my professional journey where I've contributed to diverse projects and honed my skills in full-stack development and project management.
          </Typography>
        </Box>
      </motion.div>

      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
        <div className="timeline">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
            <div class="container left">
              <div class="content">
                <img src={ReachLogo} alt="Reach Logo" style={{ width: "48px", height: "48px" }} />
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  Apr. 2023 - Present
                </Typography>
                <Typography sx={{ color: "#1e1e1e", fontWeight: "bold" }} variant="h5">
                  Full Stack Software Engineer
                </Typography>
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  <em>Reach Social</em>
                </Typography>
              </div>
            </div>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
            <div class="container right">
              <div class="content">
                <img src={SavageLogo} alt="Reach Logo" style={{ width: "48px", height: "48px", borderRadius: "8px" }} />
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  Dec. 2023 - May 2024
                </Typography>
                <Typography sx={{ color: "#1e1e1e", fontWeight: "bold" }} variant="h5">
                  Full Stack Software Engineer
                </Typography>
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  Savage Innovations
                </Typography>
              </div>
            </div>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
            <div class="container left">
              <div class="content">
                <img src={MMILogo} alt="Reach Logo" style={{ width: "48px", height: "48px" }} />
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  May 2023 - Nov. 2023
                </Typography>
                <Typography sx={{ color: "#1e1e1e", fontWeight: "bold" }} variant="h5">
                  Web Developer
                </Typography>
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  MMI Textiles
                </Typography>
              </div>
            </div>
          </motion.div>

          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 3 } }} viewport={{ once: true }}>
            <div class="container right">
              <div class="content">
                <img src={LibertyLogo} alt="Reach Logo" style={{ width: "48px", height: "48px", borderRadius: "8px" }} />
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  May 2022 - Aug. 2022
                </Typography>
                <Typography sx={{ color: "#1e1e1e", fontWeight: "bold" }} variant="h5">
                  IT Assistant
                </Typography>
                <Typography sx={{ color: "#1e1e1e" }} variant="body1">
                  Liberty Ford
                </Typography>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default Experience;
