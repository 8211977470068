import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
import HeroImg from "../assets/images/Hero.svg"; 

const HeroSection = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
      <Grid container spacing={2} sx={{ placeItems: "center", mt: "100px" }}>
        <Grid item xs={12} md={7} sx={{ mt: 2 }}>
          <div className="hero-bars">
            <div className="bars">
              <div style={{ marginLeft: "20px" }} className="bar red w50"></div>
              <div className="bar red w90"></div>
              <div style={{ marginLeft: "30px" }} className="bar blue w75"></div>
              <div className="bar yellow w100"></div>
              <div className="bar blue w65"></div>
              <div className="bar red w85"></div>
              <Typography variant="h5">
                I'm a Web & Software Developer <br />
                from Cleveland Ohio.
              </Typography>
              <div style={{ marginLeft: "20px" }} className="bar blue w55"></div>
              <div className="bar blue w75"></div>
              <div className="bar yellow w95"></div>
              <div style={{ marginLeft: "20px" }} className="bar red w65"></div>
              <div className="bar blue w45"></div>
              <div style={{ marginLeft: "25px" }} className="bar red w55"></div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 2 }}>
          <Box >
            <motion.img src={HeroImg} alt="Hero" style={{ width: "100%" }}  />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default HeroSection;
