import React from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "./themes/theme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./assets/css/fonts/fonts.css";
import "./assets/css/app.css";
import { Container } from "@mui/material";
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Container>
    </>
  );
}

export default App;
