import React from "react";
import TechStack from "../components/TechStack";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import HeroSection from "../components/Hero";
import Contact from "../components/Contact";
const HomePage = () => {
  return (
    <>
      <HeroSection />

      <Projects />

      <Experience />

      <TechStack />

      <Contact />
    </>
  );
};

export default HomePage;
