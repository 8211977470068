import { Box, Button, Card, CardActions, CardContent, Chip, Typography } from "@mui/material";
import React from "react";

const ProjectCard = ({ project }) => {
  return (
    <>
      <Card id="project-card" sx={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: "rgba(255, 255, 255, .95)" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 1 }}>
          <Box>
            <img src={project.logo} alt="logo" style={{ height: "48px" }} />
          </Box>

          <Typography variant="h5" sx={{ color: "#1e1e1e", fontWeight: "500" }}>
            {project.title}
          </Typography>
          <Box className="marquee">
            <Box className="marquee-content">
              {project.logos.map((logo, index) => {
                return <Chip sx={{ px: 1, mx: 1, backgroundColor: "#EEEEEE" }} label={logo.name} avatar={<img src={logo.src} key={index} alt="logo" />}></Chip>;
              })}
            </Box>
          </Box>
          <Typography sx={{ color: "#1e1e1e" }} variant="body1">
            {project.content}
          </Typography>
          <CardActions sx={{ marginTop: "auto" }}>
            {project.link.includes("github") ? (
              <Button
                variant="outlined"
                href={project.link}
                target="_blank"
                rel="noreferrer"
                sx={{
                  display: "flex",
                }}
                endIcon={<img src={"https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg"} alt="github" style={{ width: "16px", height: "16px" }} />}>
                View Code
              </Button>
            ) : (
              <Button variant="outlined" href={project.link} target="_blank" rel="noreferrer">
                View Site
              </Button>
            )}
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectCard;
